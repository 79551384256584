import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
} from "@chakra-ui/react";
import Gallery from "react-photo-gallery";

export default function Projects({ color }) {
  const photos = [
    {
      src: 'https://aniq-wedding-photos.s3.eu-west-2.amazonaws.com/images/SAL04590_Vintagephotoworks.jpg',
      width: 1,
      height: 1
    },
    {
      src: 'https://aniq-wedding-photos.s3.eu-west-2.amazonaws.com/images/SAL04592_Vintagephotoworks.jpg',
      width: 1,
      height: 1
    },
    {
      src: 'https://aniq-wedding-photos.s3.eu-west-2.amazonaws.com/images/SAL04807_Vintagephotoworks.jpg',
      width: 1,
      height: 1
    },
    {
      src: 'https://aniq-wedding-photos.s3.eu-west-2.amazonaws.com/images/SAL05277_Vintagephotoworks.jpg',
      width: 1,
      height: 1
    },
    {
      src: 'https://aniq-wedding-photos.s3.eu-west-2.amazonaws.com/images/SAL05298_Vintagephotoworks.jpg',
      width: 1,
      height: 1
    },
    {
      src: 'https://aniq-wedding-photos.s3.eu-west-2.amazonaws.com/images/VPM00245_Vintagephotoworks.jpg',
      width: 2,
      height: 1
    },
    {
      src: 'https://aniq-wedding-photos.s3.eu-west-2.amazonaws.com/images/VPM00296_Vintagephotoworks.jpg',
      width: 1,
      height: 1
    },
    {
      src: 'https://aniq-wedding-photos.s3.eu-west-2.amazonaws.com/images/VPM00909_Vintagephotoworks.jpg',
      width: 2,
      height: 1
    }

  ];

  return (
    <>
      <Container maxW={"8xl"} id="projects">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          pb={{ base: 20, md: 36 }}
        >
          <Stack align="center" direction="row" px={4}>
              <HStack mx={4}>
                <Text color={`${color}.400`} fontWeight={800}>
                  -
                </Text>
                <Text fontWeight={800}>Photos</Text>
              </HStack>
              <Divider orientation="horizontal" />
            </Stack>
            
            <Gallery photos={photos} />;

          {/* <SimpleGrid columns={[1, 2, 3]} px={4} spacing={4}>
            <Fade bottom>
              <Card>
                <Stack>
                  <CardBody align="left" h={[null, "40vh"]}>
                    <Heading size="sm">Photos</Heading>
                    <Text fontSize="sm" py={2}>
                      22.10.2023
                    </Text>
                  </CardBody>
                </Stack>
              </Card>
              <Card>
                <Stack>
                  <CardBody align="left" h={[null, "40vh"]}>
                    <Heading size="sm">Name</Heading>
                    <Text fontSize="sm" py={2}>
                      Description
                    </Text>
                  </CardBody>
                </Stack>
              </Card>
              <Card>
                <Stack>
                  <CardBody align="left" h={[null, "40vh"]}>
                    <Heading size="sm">Name</Heading>
                    <Text fontSize="sm" py={2}>
                      Description
                    </Text>
                  </CardBody>
                </Stack>
              </Card>
            </Fade>
          </SimpleGrid> */}
        </Stack>
      </Container>
    </>
  );
}
